import { childOp } from './checkbox_child_function';

function collectAction() {
  let parent = document.getElementById('parent');
  let child = document.getElementsByClassName('child_check');
  let disable_ele = document.getElementById('submit_collect');
  const filter_form = document.getElementById('filter_form');

  console.log(child);

  if (parent && child) {
    var check_child = [];
    let cardTransactionRow = document.getElementsByClassName('transaction_card');
    
    // Parent Operations
    parent.onclick = () => {
      var count_all = 0;
      let print_count_all = document.getElementById('print_number');

      if(cardTransactionRow.length > 0) {
        for(let i = 0; i < cardTransactionRow.length; i++) {
          if (cardTransactionRow[i].querySelector("input[type=checkbox]")) {
            if (parent.checked == true) {
              disable_ele.style = 'background-color: #3D8DCB; cursor: pointer;'
              disable_ele.disabled = false;
              cardTransactionRow[i].querySelector("input[type=checkbox]").checked = true
              count_all += parseInt(cardTransactionRow[i].querySelector("p[data-select='amount']").textContent)
              check_child.push(cardTransactionRow[i].querySelector("input[type=checkbox]").checked);
              print_count_all.textContent = count_all;
            } else {
              disable_ele.style = 'background-color: gray; cursor: not-allowed'
              disable_ele.disabled = true
              cardTransactionRow[i].querySelector("input[type=checkbox]").checked = false
              check_child.push(cardTransactionRow[i].querySelector("input[type=checkbox]").checked)
              print_count_all.textContent = 0;
            }
          }
        }
      }
    }

    filter_form.addEventListener('submit', () => {
      let print_count_all = document.getElementById('print_number');
      print_count_all.textContent = 0;
    })
  }

  childOp(child, check_child, disable_ele);
}

export { collectAction };
