import Rails from '@rails/ujs';
import { collectAction } from './collect_action/collect_action';

function callFilterUpdate() {
  const filter_form = document.getElementById('filter_form');
  const checkboxForUsers = document.getElementById('users_checked');
  const checkboxForMerchants = document.getElementById('merchants_checked');
  const checkedSerialNumber = document.getElementById('serial_numbers_checked');
  var locale = window.location.pathname.split("/")[1];

  if (filter_form) {
    let user_ids = [];
    let merchant_ids = [];
    let serial_number_ids = [];

    filter_form.addEventListener('submit', (e) => {
      e.preventDefault();

      const load_more = document.getElementById('load_more');
      const reset = document.getElementById('reset');
      const reset_filter = document.getElementById('reset_filter');
      const collect_status = document.getElementById('collect_status');
      const nav_transaction_type = document.getElementById('nav_transaction_type');
      
      nav_transaction_type.style = 'display: none';
      reset_filter.style = 'display: block';
      if(load_more && reset) {
        load_more.style = 'display: none';
        reset.style = 'display: none';
      }

      if(checkboxForMerchants.getElementsByClassName('checkbox').length > 0) {
        for(let i = 0; i < checkboxForMerchants.getElementsByClassName('checkbox').length; i++) {
          if(checkboxForMerchants.getElementsByClassName('checkbox')[i].checked) {
            merchant_ids.push(checkboxForMerchants.getElementsByClassName('checkbox')[i].value)
          } else {
            merchant_ids = merchant_ids.filter(item => item !== checkboxForMerchants.getElementsByClassName('checkbox')[i].value)
            console.log(merchant_ids);
          }
        }
      }


      if(checkboxForUsers.getElementsByClassName('checkbox').length > 0) {
        for(let i = 0; i < checkboxForUsers.getElementsByClassName('checkbox').length; i++) {
          if(checkboxForUsers.getElementsByClassName('checkbox')[i].checked) {
            user_ids.push(checkboxForUsers.getElementsByClassName('checkbox')[i].value)
          } else {
            user_ids = user_ids.filter(item => item !== checkboxForUsers.getElementsByClassName('checkbox')[i].value)
            console.log(user_ids);
          }
        }
      }

      if(checkedSerialNumber.getElementsByClassName('checkbox').length > 0) {
        for(let i = 0; i < checkedSerialNumber.getElementsByClassName('checkbox').length; i++) {
          if(checkedSerialNumber.getElementsByClassName('checkbox')[i].checked) {
            serial_number_ids.push(checkedSerialNumber.getElementsByClassName('checkbox')[i].value)
          } else {
            serial_number_ids = serial_number_ids.filter(item => item !== checkedSerialNumber.getElementsByClassName('checkbox')[i].value)
            console.log(serial_number_ids);
          }
        }
      }

      card_transactions_collection.innerHTML = `<div class='loading'>${translateItem().loading}</div>`

      Rails.ajax({
        url: `/${locale}/card_transactions`,
        type: "get",
        data: `user_ids=${user_ids}&merchant_ids=${merchant_ids}&serial_numbers=${serial_number_ids}&collect=${collect_status.value}`,
        success: function(data) {

          let card_transactions_collection = document.getElementById('card_transactions_collection');
          
          if(data.data && data.data.length > 0) {
            var htmlCards = ``;

            for (let i = 0; i < data.data.length - 1; i++) {
              card_transactions_collection.innerHTML = ''

              htmlCards += `<div class="flex flex-col justify-between p-2 bg-white rounded-lg shadow-xs dark:bg-gray-800 mb-1 transaction_card filter_cards" id="card_transaction_${data.data[i].id}">
  
              <div class="flex justify-between">
                <p class="card_details_custom text-sm font-medium text-gray-600 dark:text-gray-400">${data.data[i].card_serial}</p>
                ${viewNotcollected(data.data[i].collect, data.data[i].id)}
              </div>
            
              <div class="flex justify-between mt-1">
                <p class="text-sm font-medium text-gray-600 dark:text-gray-400"></p>
              </div>
            
              <div class="flex justify-between">
                <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">${translateItem().card_amount}</p>
                <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                  ${data.data[i].card_amount}
                </p>
              </div>
            
              <div class="flex justify-between">
                <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">${translateItem().merchant_ref}</p>
                <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                  ${data.data[i].merchant_ref}
                </p>
              </div>
            
              <div class="flex justify-between">
                <p class="mr-5 text-sm font-medium text-gray-600 dark:text-grrchant Referanceay-400">${translateItem().merchant}</p>
                <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                ${data.data[i].merchant_name}
                </p>
              </div>
            
              <div class="flex justify-between">
                <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">${translateItem().created_by}</p>
                <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                  ${data.data[i].created_by}
                </p>
              </div>
            
              <div class="flex justify-between">
                <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">${translateItem().amount}</p>
                <p class="amount text-sm font-semibold text-gray-700 dark:text-gray-200" data-select="amount">
                  ${data.data[i].amount} EGP
                </p>
              </div>
            
              <div class="flex mb-3 justify-between">
                <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">${translateItem().device_serial}</p>
                <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">${data.data[i].serial_number}</p>
              </div>
            
              <div class="flex justify-between mt-1">
                <p class="amount text-sm font-semibold text-gray-700 dark:text-gray-200 border p-1 rounded">
                   ${data.data[i].transaction_time}
                </p>
                <p class="text-sm font-medium text-gray-600 dark:text-gray-400">
                  ${collectReturn(data.data[i].collect)}
                </p>
              </div>
            </div>`
            }

            card_transactions_collection.innerHTML = htmlCards;
            //user_ids = []
            //checkboxForUsers.innerHTML = ''
            filter_form.style = 'display: none'
            collectAction();
          } else {
            card_transactions_collection.innerHTML = "<div class='not_found_transactions'> No transactions Found </div>";
            //user_ids = []
            //checkboxForUsers.innerHTML = '';
            filter_form.style = 'display: none'
          }
        },
        error: function(data) {
          console.log(data);
        }
       })
    })
  }

      // Ajax Request
      /*Rails.ajax({
        url: '/card_transactions',
        type: "get",
        data: `${urlParams}q=filter`,
        success: function(data) {
          // console.log(data);
          // console.log(`${urlParams}q=filter`);

          let card_transactions_collection = document.getElementById('card_transactions_collection');

          if (data.data && data.data.length > 1) {
            // console.log(data.data);

          var htmlCards = ``;

          for(let i = 0; i < data.data.length - 1; i++) {
            card_transactions_collection.innerHTML = ''
            console.log(card_transactions_collection);
            htmlCards += ``
          }
          card_transactions_collection.innerHTML = htmlCards;
        }
        },
        error: function(data) {
          // console.log(data);
        }
      })*/
}

function collectReturn(collect) {
  if (collect == 1) {
    return `
    <span class="py-1 font-semibold leading-tight text-green-700 rounded dark:bg-green-700 dark:text-green-100" style="user-select: auto;">
      Collected
    </span> 
    `
  } else {
    return `
    <span class="py-1 font-semibold leading-tight text-gray-500 rounded dark:bg-green-700 dark:text-green-100" style="user-select: auto;">
      Not collected
    </span> 
    `
  }
}

function viewNotcollected(collect, id)  {
  if (collect == 1) {
    return `<span></span>`
  } else {
    return `<input type="checkbox" name="card_transaction_ids[]" id="card_transaction_ids_" value="${id}" multiple="multiple" form="collect_actions_form" data-checkbox-select-all-target="child" data-action="change->checkbox-select-all#toggleParent" class="child_check w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-400 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">    `
  }
}

function translateItem() {
  const locale = window.location.pathname.split("/")[1];
  
  if (locale == 'en') {
    return {
      card_amount: 'Card Amount',
      merchant_ref: 'M.R',
      merchant: 'Merchant',
      device_serial: 'Device serial',
      created_by: 'Created By',
      amount: 'Amount',
      loading: 'Loading.............'
    }
  } else {
    return {
      card_amount: 'قيمة الكارت',
      merchant_ref: 'ر.ت.ت',
      merchant: 'البائع',
      device_serial: 'الرقم التسلسلي',
      created_by: 'بواسطه',
      amount: 'قيمة المعاملة',
      loading: 'جاري التحميل.......'
    }
  }
}

/*function languageFilter(card_id) {
  new google.translate.TranslateElement({pageLanguage: 'en'}, 'card_transaction_');
}*/

export { callFilterUpdate }








/*

<div class="flex flex-col justify-between p-2 bg-white rounded-lg shadow-xs dark:bg-gray-800 mb-1 transaction_card" id="card_transaction_97">
  
  <div class="flex justify-between">
    <p class="card_details_custom text-sm font-medium text-gray-600 dark:text-gray-400">AAAAAA00016904</p>
      <input type="checkbox" name="card_transaction_ids[]" id="card_transaction_ids_" value="97" multiple="multiple" form="collect_actions_form" data-checkbox-select-all-target="child" data-action="change->checkbox-select-all#toggleParent" class="child_check w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-400 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
  </div>

  <div class="flex justify-between mt-1">
    <p class="text-sm font-medium text-gray-600 dark:text-gray-400"></p>
  </div>

  <div class="flex justify-between">
    <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">Card balance:</p>
    <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
      54
    </p>
  </div>

  <div class="flex justify-between">
    <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">Merchant Referance:</p>
    <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
      92100038541662641806
    </p>
  </div>

  <div class="flex justify-between">
    <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">Merchant:</p>
    <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
      EAZY MOBILITY
    </p>
  </div>

  <div class="flex justify-between">
    <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">Created By:</p>
    <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">
      mmsales
    </p>
  </div>

  <div class="flex justify-between">
    <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">Amount:</p>
    <p class="amount text-sm font-semibold text-gray-700 dark:text-gray-200" data-select="amount">
      50
    </p>
  </div>

  <div class="flex mb-3 justify-between">
    <p class="mr-5 text-sm font-medium text-gray-600 dark:text-gray-400">Device serial:</p>
    <p class="text-sm font-semibold text-gray-700 dark:text-gray-200">9210003854</p>
  </div>

  <div class="flex justify-between mt-1">
    <p class="amount text-sm font-semibold text-gray-700 dark:text-gray-200 border p-1 rounded">
       8 Sep 2022,  3:56 pm
    </p>
    <p class="text-sm font-medium text-gray-600 dark:text-gray-400">
        <span class="py-1 font-semibold leading-tight text-gray-500 rounded dark:bg-green-700 dark:text-green-100" style="user-select: auto;">
          Not collected
        </span> 
    </p>
  </div>
</div>



*/