function importFunc() {
  if (document.getElementById('import_btn')) {
    document.getElementById('import_btn').onclick = () => {
      document.getElementById('import_drop').style = 'display: block;'
    }

    document.getElementById('exit').onclick = () => {
      document.getElementById('import_drop').style = 'display: none;'
    }
  }
}

function resetPasswordFunc() {
  if (document.getElementById('reset_password_btn')) {
    document.getElementById('reset_password_btn').onclick = () => {
      document.getElementById('reset_password_drop').style = 'display: block;'
    }

    document.getElementById('exit_reset_password').onclick = () => {
      document.getElementById('reset_password_drop').style = 'display: none;'
    }
  }
}


export { importFunc, resetPasswordFunc }
