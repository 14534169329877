// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails, { href } from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { collectAction } from './collect_action/collect_action';
import { SidebarToggle } from './sidebar_hide';
import { toggleBackground } from './sidebar_links';
import { updateActivelink } from './update_active_link';
import { importFunc, resetPasswordFunc } from './import';
import { callFilterUpdate } from './call_filter_update';
import { userFilterAutoload } from './user_filter_autoload';
import { merchantFilterAutoload } from './merchant_filter_autoload';
import { serialNumberFilterAutoload } from './serial_number_filter_autoload';

document.addEventListener('DOMContentLoaded', () => {
  console.log('loaded.......');
  if (document.getElementById('logout')) {
    document.getElementById('logout').onclick = () => {
      localStorage.clear();
    }
  }

  if(document.getElementById('notice')) {
    setTimeout(()=> {
      document.getElementById('notice').style = 'display: none;'
    }, 2000)
  }

  if(document.getElementById('alert')) {
    setTimeout(()=> {
      document.getElementById('alert').style = 'display: none;'
    }, 2000)
  }

  let filterBtn = document.getElementById('filter_btnn');
  let filterForm = document.getElementById('filter_form');
  let closeFilter = document.getElementById('close_filter');
  let getLoadMore = document.getElementById('load_more');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let loadMoreParam = urlParams.get('load_more');
  var locale = window.location.pathname.split("/")[1];

  if(!localStorage.getItem('load_count') || !loadMoreParam || loadMoreParam == 10) {
    localStorage.setItem('load_count', 10);
  }

  if(getLoadMore){
    getLoadMore.addEventListener('click', () => {
      const count = Number(localStorage.getItem('load_count')) + 10;
      // console.log(localStorage);
      localStorage.setItem('load_count', count);
      getLoadMore.setAttribute('href', `/${locale}/card_transactions?load_more=${localStorage.getItem('load_count')}`)
    })
  }

  function filterFunc() {
    if (closeFilter) {
      closeFilter.addEventListener('click', () => {
        filterForm.style = 'display: none;'
      })
    }

    if(filterBtn) {
      filterBtn.addEventListener('click', () => {
        filterForm.style = 'display: block;'
      })
    }
  }

  /*const display_total_collected = document.getElementById('display_total_collected');
  const search_display_btns = document.getElementById('search_display_btns');
  const submit_filter_id = document.getElementById('submit_filter_id');

  submit_filter_id.addEventListener('submit', () => {
    display_total_collected.style = 'display: block;'
    search_display_btns.style = 'display: block;'
  })*/

  //handle_export_action();
  collectAction();
  serialNumberFilterAutoload();
  merchantFilterAutoload();
  userFilterAutoload();
  filterFunc();
  callFilterUpdate();
  toggleBackground();
  //updateActivelink();
  SidebarToggle();
  importFunc();
  resetPasswordFunc();
});

Rails.start()
ActiveStorage.start()
