import Rails from '@rails/ujs';

function serialNumberFilterAutoload() {
  const inputFilterAutoload = document.getElementById('serial_number_filter_autoload');
  const inputSerialNumberFilterSelect = document.getElementById('serial_number');
  const selectedSerialNumber = document.getElementById('serial_numbers_checked');
  var locale = window.location.pathname.split("/")[1];

  if (inputFilterAutoload && inputSerialNumberFilterSelect) {
    inputSerialNumberFilterSelect.style.display = 'none';

    inputFilterAutoload.addEventListener('focus', (e) => {
      inputSerialNumberFilterSelect.setAttribute('expandto', '5');
      inputSerialNumberFilterSelect.setAttribute('tabindex', '5');
      inputSerialNumberFilterSelect.setAttribute('size', '5');
      inputSerialNumberFilterSelect.style.display = 'block';
    })


    let selectedNumbers = [];

    inputSerialNumberFilterSelect.childNodes.forEach((option) => {
      option.addEventListener('click', () => {
        if(!selectedNumbers.includes(option.value)) {
          selectedNumbers.push(option.value);
          selectedSerialNumber.innerHTML += `<span class='auto_display_selected_user mt-2'>${option.value} <input type='checkbox' class='checkbox' name='user_ids[]' id='user_ids_' form="filter_form" multiple="multiple" value='${option.value}' id='${option.value}' checked ></span>`;
        }
      })
    })

    inputFilterAutoload.addEventListener('keyup', (e) => {//[ /SerialNumber_search ] inputFilterAutoload.value
      let selectContent = ``;
      Rails.ajax({ 
        url: `/${locale}/serial_number_search`,
        type: 'get',
        data: `serial_number_name=${inputFilterAutoload.value}`,
        success: function (data) {
          console.log(data.data);
          for(let i = 0; i < data.data.length - 1; i++) {
            console.log(data.data[i].name);
            if (data.data[i].name != '') {
              selectContent += `<option id=${data.data[i].name}>${data.data[i].name}</option>`
            }
          }

          inputSerialNumberFilterSelect.innerHTML = selectContent;

          inputSerialNumberFilterSelect.childNodes.forEach((option) => {
            option.addEventListener('click', () => {
              if(!selectedNumbers.includes(option.value)) {
                selectedNumbers.push(option.value);
                selectedSerialNumber.innerHTML += `<span class='auto_display_selected_user mt-2'>${option.value} <input type='checkbox' class='checkbox' name='user_ids[]' id='user_ids_' form="filter_form" multiple="multiple" value='${option.value}' id='${option.value}' checked ></span>`;
              }
            })
          })
        },
        error: function(data) {
          console.log(data);
        }
      })
    })
  }
}

export { serialNumberFilterAutoload }
