function updateActivelink() {
  var header = document.getElementById("sidebar");

  if (header) {
    var btns = header.getElementsByClassName("btn");
    if (localStorage.getItem('url_active')) {
      for (var i = 0; i < btns.length; i++) {
        if(btns[i].getElementsByTagName('span')[0].innerHTML == localStorage.getItem('url_active').replace(/(\r\n|\n|\r)/gm, "").replace(/ /g,'')) {
          btns[i].classList = localStorage.getItem('active_class')
        }
      }
    }
  }
}

export { updateActivelink }
