function SidebarToggle() {
  const hamburgerBtn = document.getElementById('mobile_hamburger');
  const sideBar = document.getElementById('side_bar');

  if (hamburgerBtn && sideBar) {
    hamburgerBtn.addEventListener('click', ()=> {
      if (sideBar.style.getPropertyValue('display') == 'block') {
        sideBar.style.setProperty('display', 'none');
      } else {
        sideBar.style.setProperty('display', 'block');
      }
    })
  }
}


export { SidebarToggle }
