function toggleBackground() {
  var header = document.getElementById("sidebar");

  if (header) {
    var btns = header.getElementsByClassName("btn");

    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
      var current = document.getElementsByClassName("active_btn");
      current[0].className = current[0].className.replace(" active_btn", "");
      this.className += " active_btn";
      localStorage.setItem('url_active', `${this.textContent}`)
      localStorage.setItem('active_class', `${this.className}`)
      });
    }
  }
}

export { toggleBackground }
