import Rails from '@rails/ujs';

function userFilterAutoload() {
  const inputFilterAutoload = document.getElementById('user_filter_autoload');
  const inputUserFilterSelect = document.getElementById('user');
  const checkboxForUsers = document.getElementById('users_checked');
  var locale = window.location.pathname.split("/")[1];

  if(inputUserFilterSelect && inputFilterAutoload) {
    inputUserFilterSelect.style.display = 'none';

    inputFilterAutoload.addEventListener('focus', (e) => {
      inputUserFilterSelect.setAttribute('expandto', '5');
      inputUserFilterSelect.setAttribute('tabindex', '5');
      inputUserFilterSelect.setAttribute('size', '5');

      inputUserFilterSelect.style.display = 'block';
    })

    let selectedOptions = [];

    inputUserFilterSelect.childNodes.forEach((option) => {
      option.addEventListener('click', () => {
        if(!selectedOptions.includes(option.value)) {
          selectedOptions.push(option.value);
          checkboxForUsers.innerHTML += `<span class='auto_display_selected_user mt-2'>${option.value} <input type='checkbox' class='checkbox' name='user_ids[]' id='user_ids_' form="filter_form" multiple="multiple" value='${option.value}' id='${option.value}' checked ></span>`;
        }
      })
    })

    console.log(checkboxForUsers);

    inputFilterAutoload.addEventListener('keyup', (e) => {//[ /user_search ] inputFilterAutoload.value
      let selectContent = ``;
      Rails.ajax({ 
        url: `/${locale}/user_search`,
        type: 'get',
        data: `user_name=${inputFilterAutoload.value}`,
        success: function (data) {
          console.log(data.data);
          for(let i = 0; i < data.data.length - 1; i++) {
            console.log(data.data[i].name);
            if (data.data[i].name != '') {
              selectContent += `<option value=${data.data[i].name}>${data.data[i].name}</option>`
            }
          }
          console.log(selectContent);
          inputUserFilterSelect.innerHTML = selectContent;

          inputUserFilterSelect.childNodes.forEach((option) => {
             option.addEventListener('click', () => {
               if(!selectedOptions.includes(option.value)) {
                selectedOptions.push(option.value);
                checkboxForUsers.innerHTML += `<span class='auto_display_selected_user mt-2'>${option.value} <input type='checkbox' class='checkbox' name='user_ids[]' id='user_ids_' form="filter_form" multiple="multiple" value='${option.value}' id='${option.value}' checked ></span>`;
               }
             })
          })
        },
        error: function(data) {
          console.log(data);
        }
      })
    })
  }
}

export { userFilterAutoload }
