function childOp(child, check_child, disable_ele) {
  for(let k = 0; k < child.length; k++) {
    var amount_values = [];

    child[k].onclick = () => {
      var count = 0;

      console.log('clicked');

      if (check_child.includes(false)) parent.checked = false;

      if (child[k].checked == true) {
        disable_ele.style = 'background-color: #3D8DCB; cursor: pointer;'
        disable_ele.disabled = false
        amount_values.push({ 
          id: parseInt(child[k].getAttribute('value')),
          colected_amount: parseInt(child[k].parentElement.parentElement.querySelector("p[data-select='amount']").textContent)
        });
      } else {
        disable_ele.style = 'background-color: gray; cursor: not-allowed'
        disable_ele.disabled = true
        amount_values.pop();
      }
  
      localStorage.setItem('colected_amount', JSON.stringify(amount_values));

      JSON.parse(localStorage.getItem('colected_amount')).forEach(obj => {
        count += obj.colected_amount
      });
      document.getElementById('print_number').textContent = count;
    }
  }

  console.log('Done');
}

export { childOp };