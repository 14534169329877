import Rails from '@rails/ujs';

function merchantFilterAutoload() {
  const inputFilterAutoload = document.getElementById('merchant_filter_autoload');
  const inputMerchantFilterSelect = document.getElementById('merchant');
  const checkboxForMerchants = document.getElementById('merchants_checked');
  var locale = window.location.pathname.split("/")[1];


  if(inputFilterAutoload && inputMerchantFilterSelect) {

    inputMerchantFilterSelect.style.display = 'none';

    inputFilterAutoload.addEventListener('focus', (e) => {
      inputMerchantFilterSelect.setAttribute('expandto', '5');
      inputMerchantFilterSelect.setAttribute('tabindex', '5');
      inputMerchantFilterSelect.setAttribute('size', '5');
      inputMerchantFilterSelect.style.display = 'block';
    })


     let selectedMerchant = [];

    inputMerchantFilterSelect.childNodes.forEach((option) => {
      option.addEventListener('click', () => {
        if(!selectedMerchant.includes(option.value)) {
          selectedMerchant.push(option.value);
          checkboxForMerchants.innerHTML += `<span class='auto_display_selected_user mt-2'>${option.value} <input type='checkbox' class='checkbox' name='user_ids[]' id='user_ids_' form="filter_form" multiple="multiple" value='${option.value}' id='${option.value}' checked ></span>`;
        }
      })
    })

    inputFilterAutoload.addEventListener('keyup', (e) => {//[ /merchant_search ] inputFilterAutoload.value
      let selectContent = ``;
      Rails.ajax({ 
        url: `/${locale}/merchant_search`,
        type: 'get',
        data: `merchant_name=${inputFilterAutoload.value}`,
        success: function (data) {
          console.log(data.data);
          for(let i = 0; i < data.data.length - 1; i++) {
            console.log(data.data[i].name);
            if (data.data[i].name != '') {
              selectContent += `<option id=${data.data[i].name}>${data.data[i].name}</option>`
            }
          }
          console.log(selectContent);
          inputMerchantFilterSelect.innerHTML = selectContent;

          inputMerchantFilterSelect.childNodes.forEach((option) => {
            option.addEventListener('click', () => {
              if(!selectedMerchant.includes(option.value)) {
               selectedMerchant.push(option.value);
               checkboxForMerchants.innerHTML += `<span class='auto_display_selected_user mt-2'>${option.value} <input type='checkbox' class='checkbox' name='user_ids[]' id='user_ids_' form="filter_form" multiple="multiple" value='${option.value}' id='${option.value}' checked ></span>`;
              }
            })
         })
        },
        error: function(data) {
          console.log(data);
        }
      })
    })
  }
}

export { merchantFilterAutoload }
